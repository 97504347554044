import axios from "axios";
import $router from "@/router/index";
import { ElMessage } from "element-plus";

const service = axios.create({
    timeout: 30000,
    withCredentials: true,
    baseURL: process.env.VUE_APP_BASE_URL,
});

// axios拦截器
service.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

service.interceptors.response.use(
    (response) => {
        if (response.data.code === 200) {
            return response;
        } else {
            ElMessage({
                message: response.data.code === 401 ? "登录状态失效，请重新登录！" : response.data.msg,
                type: "error",
                grouping: true,
                onClose: () => {
                    if (response.data.code === 401) {
                        $router.push({ name: "Login" });
                    }
                },
            });
        }
    },
    (error) => {
        if (error.response) {
            return Promise.reject(error.response); // 返回接口返回的错误信息
        }
    },
);

// 请求封装
export function httpPost(url: string, params: object, config?: object) {
    return service({
        method: "post",
        url: url,
        data: params,
        ...config,
    });
}

export function httpGet(url: string, params: object, config?: object) {
    return service({
        method: "get",
        url: url,
        params: params,
        ...config,
    });
}

export function httpPut(url: string, params: object, config?: object) {
    return service({
        method: "put",
        url: url,
        data: params,
        ...config,
    });
}

export function httpDelete(url: string, params: object, config?: object) {
    return service({
        method: "delete",
        url: url,
        data: params,
        ...config,
    });
}
