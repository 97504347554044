import { httpGet, httpPost } from "@/api/request";
import { useStoreUser } from "@/store";

// 账号密码登录
export function AUTH_LOGIN(params: object) {
    return httpPost("/login", params);
}

// 获取用户信息
export function GET_USER_INFO(params: object) {
    return httpGet("/getInfo", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}
