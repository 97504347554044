import { getLocalStorage } from "@/utils/storage";

let userData = getLocalStorage("userData");
let redirectPath = "";
if (!userData) {
    redirectPath = "/login";
} else {
    if (userData.user.deptId === 2) {
        redirectPath = "/patrol/list";
    } else if (userData.user.deptId === 3) {
        redirectPath = "/goods/install/list";
    } else {
        redirectPath = "/equipment/online/list";
    }
}

export default [
    // 登录
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/Auth/Login.vue"),
    },
    // 主体
    {
        path: "/",
        redirect: redirectPath,
        name: "Layout",
        component: () => import("../views/Layout/Layout.vue"),
        children: [
            // 权限管理
            {
                path: "/user/list",
                name: "UserList",
                meta: {
                    title: "用户管理",
                    breadcrumb: ["用户管理"],
                },
                component: () => import("../views/Container/Permission/User/UserList.vue"),
            },
            {
                path: "/role/list",
                name: "RoleList",
                meta: {
                    title: "角色管理",
                    breadcrumb: ["角色管理"],
                },
                component: () => import("../views/Container/Permission/Role/RoleList.vue"),
            },
            {
                path: "/menu/list",
                name: "MenuList",
                meta: {
                    title: "菜单管理",
                    breadcrumb: ["菜单管理"],
                },
                component: () => import("../views/Container/Permission/Menu/MenuList.vue"),
            },
            {
                path: "/dept/list",
                name: "DeptList",
                meta: {
                    title: "部门管理",
                    breadcrumb: ["部门管理"],
                },
                component: () => import("../views/Container/Permission/Dept/DeptList.vue"),
            },
            // 序列号管理
            {
                path: "/serial_number/list/factory",
                name: "SerialNumberListFactory",
                meta: {
                    title: "序列号管理-生产厂家",
                    breadcrumb: ["序列号管理", "生产厂家"],
                    type: 1,
                },
                component: () => import("../views/Container/SerialNumber/List/SerialNumberList.vue"),
            },
            {
                path: "/serial_number/list/assets",
                name: "SerialNumberListAssets",
                meta: {
                    title: "序列号管理-资产管理",
                    breadcrumb: ["序列号管理", "资产管理"],
                    type: 2,
                },
                component: () => import("../views/Container/SerialNumber/List/SerialNumberList.vue"),
            },
            {
                path: "/serial_number/rule/list",
                name: "SerialNumberRuleList",
                meta: {
                    title: "序列号规则",
                    breadcrumb: ["序列号管理", "规则管理"],
                },
                component: () => import("../views/Container/SerialNumber/Rule/SerialNumberRuleList.vue"),
            },
            // 资产管理
            {
                path: "/asset/pole/list",
                name: "AssetsPoleList",
                meta: {
                    title: "电杆资产管理",
                    breadcrumb: ["资产管理", "电杆资产管理"],
                },
                component: () => import("../views/Container/Assets/Pole/AssetsPoleList.vue"),
            },
            // 货物管理
            {
                path: "/goods/install/list",
                name: "GoodsInstallList",
                meta: {
                    title: "安装管理",
                    breadcrumb: ["货物管理", "安装管理"],
                },
                component: () => import("../views/Container/Goods/Install/GoodsInstallList.vue"),
            },
            // 报警管理
            {
                path: "/alarm/list",
                name: "AlarmList",
                meta: {
                    title: "报警管理",
                    breadcrumb: ["报警管理"],
                },
                component: () => import("../views/Container/Alarm/AlarmList.vue"),
            },
            // 设备管理
            {
                path: "/equipment/online/list",
                name: "EquipmentOnline",
                meta: {
                    title: "在线电杆管理",
                    breadcrumb: ["在线电杆管理"],
                },
                component: () => import("../views/Container/Equipment/Online/EquipmentOnline.vue"),
            },
            {
                path: "/equipment/map",
                name: "EquipmentMap",
                meta: {
                    title: "在线电杆定位",
                    breadcrumb: ["在线电杆定位"],
                },
                component: () => import("../views/Container/Equipment/Map/EquipmentMap.vue"),
            },
            {
                path: "/equipment/terminal/list",
                name: "EquipmentTerminal",
                meta: {
                    title: "智能终端设备管理",
                    breadcrumb: ["智能终端设备管理"],
                },
                component: () => import("../views/Container/Equipment/Terminal/EquipmentTerminal.vue"),
            },
            // 巡更管理
            {
                path: "/patrol/list",
                name: "Patrol",
                meta: {
                    title: "巡更管理",
                    breadcrumb: ["巡更管理"],
                },
                component: () => import("../views/Container/Patrol/PatrolList.vue"),
            },
        ],
    },
];
