import { defineStore } from "pinia";
import { clearLocalStorage, delCookie, getCookie, getLocalStorage, setCookie, setLocalStorage } from "@/utils/storage";
import { GET_USER_INFO } from "@/api/auth";

interface StateProps {
    token: string;
    userData: object;
}

export const useStoreUser = defineStore({
    id: "user",
    state: (): StateProps => ({
        token: getCookie("token") || "",
        userData: getLocalStorage("userData") || {},
    }),
    getters: {
        getToken: (state): string => state.token,
        getUserData: (state): object => state.userData,
    },
    actions: {
        setToken(value: string) {
            this.token = value;
            setCookie("token", value, 2592000);
        },
        setUserData() {
            GET_USER_INFO({}).then((res) => {
                this.userData = {
                    user: res.data.user,
                    roles: res.data.roles,
                    permissions: res.data.permissions,
                };
                setLocalStorage("userData", this.userData);
            });
        },
        clearToken() {
            this.token = "";
            delCookie("token");
        },
        clearUserData() {
            this.userData = {};
            clearLocalStorage("userData");
        },
    },
});
