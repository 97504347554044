<template>
    <router-view />
</template>

<script lang="ts" setup></script>

<style lang="scss">
@import "./assets/style/style.scss";
@import "./assets/font/iconfont.css";
</style>
